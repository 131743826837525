<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="name"
                  return-object
                  :items="customers"
                  v-model="customer"
                  @change="onChangeCustomer"
                  :rules="[v => !!v || 'Customer is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>No Form # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="lastFormNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="purchaseOrderNumber"
                  return-object
                  v-model="purchaseOrder"
                  :items="salePurchaseOrders"
                  @change="onChangePurchaseOrder"
                  :rules="[v => !!v || 'PO is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    @input="menuInvoiceDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Term</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 1 || 'Term must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Bill To<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="form.billTo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bill To Contact</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.billToContact"
                  :items="contacts"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Invoice<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentageInvoice"
                  suffix="%"
                  @change="onInputPercentageInvoice"
                  :rules="[
                    v =>
                      (v >= 1 && v <= 100) ||
                      'Percentage Invoice must more than equals 1 and less than equals 100',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentagePpn"
                  suffix="%"
                  @change="onInputPercentagePpn"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.rate"
                  prefix="Rp."
                  :rules="[v => v >= 1 || 'Rate must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox v-model="form.fp" label="Faktur Pajak Digunggung"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && !form.fp">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3">
                <v-subheader>DP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.dp"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Site</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.site }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Sales</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.salesName }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Sub Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Invoice</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterInvoice) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-product
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @save="saveItem"
      @close="close"
      @deleteItem="deleteItem"
    ></dialog-sale-product>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogSaleProduct from "@/components/DialogSaleProduct";

export default {
  name: "create-sale-proforma",
  components: {
    "dialog-sale-product": DialogSaleProduct,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: null,
      invoiceDuration: 0,
      customerId: null,
      customerName: null,
      customerAddress: null,
      purchaseOrderId: null,
      purchaseOrderNumber: "",
      purchaseOrderCustomer: "",
      salesName: "",
      percentageInvoice: 0,
      percentagePpn: 11,
      currency: "",
      site: "",
      remark: "",
      percentageUpTo: 0,
      billTo: "",
      billToContact: null,
      taxInvoiceSerialNumber: "",
      materialDocumentNumber: "",
      totalAmount: 0,
      rate: 1,
      fp: true,
      taxAmount: 0,
      totalAmountAfterInvoice: 0,
      totalAmountAfterTax: 0,
      products: [],
      dp: false,
    },
    menuInvoiceDate: false,
    form: {},
    items: [],
    customer: null,
    purchaseOrder: null,
    salePurchaseOrders: [],
    accounts: [],
    dialog: false,
    item: {},
    index: -1,
    twoDigit: "",
    oneDigit: "",
    arrayFronts: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
    arrayFrontNexts: [0, 1],
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      // {
      //   text: "DO#",
      //   value: "doNumber",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Mat Doc#",
      //   value: "materialDocumentNumber",
      //   sortable: false,
      //   divider: true,
      // },
      // {
      //   text: "Account",
      //   value: "accountName",
      //   sortable: false,
      //   divider: true,
      // },
    ],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.total;
      });
      return subtotal;
    },
    ...mapState("external", ["customers", "contacts"]),
    ...mapState("taxNumber", ["serialNumber"]),
    ...mapState("saleProforma", ["lastFormNumber"]),
  },

  watch: {
    totalAmount(val) {
      this.form.totalAmount = val;
    },
  },

  methods: {
    submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.products = this.items;
        this.$store.dispatch("saleProforma/create", this.form);
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    onChangeCustomer(val) {
      this.form.customerId = val.id;
      this.form.customerName = val.name;
      this.form.billTo = val.name;
      this.getAdditionalDataFromCustomerId(val.id);
    },
    async getAdditionalDataFromCustomerId(id) {
      const response = await this.$store.dispatch("external/getSalePurchaseOrders", id);
      if (response.status === 200) {
        this.salePurchaseOrders = response.data;
      }
      await this.$store.dispatch("external/getContact", id);
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    onChangePurchaseOrder(val) {
      this.form.purchaseOrderId = val.id;
      this.form.purchaseOrderNumber = val.purchaseOrderNumber;
      this.form.customerAddress = val.address.street;
      this.form.salesName = val.user.name;
      this.items = val.products;
      this.form.currency = val.currency;
      this.form.site = val.site;
      this.form.billToContact = val.contact.id;
    },
    onInputPercentageInvoice() {
      this.form.totalAmountAfterInvoice = this.totalAmount * (this.form.percentageInvoice / 100);
      this.form.taxAmount = this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100);
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      this.form.percentageUpTo = this.form.percentageInvoice;
    },
    onInputPercentagePpn() {
      this.form.taxAmount = this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100);
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    saveItem(data) {
      this.items[data.index] = data.item;
      this.close();
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    clear() {
      this.customer = null;
      this.purchaseOrder = null;
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getInvoiceNumber() {
      const response = await this.$store.dispatch("saleProforma/invoiceNumber");
      if (response.status === 200) {
        this.form.invoiceNumber = response.data;
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("taxNumber/getNumber"),
        await this.$store.dispatch("saleProforma/getLastFormNumber"),
        await this.$store.dispatch("external/getCustomers"),
        await this.getAccounts(),
        this.getInvoiceNumber(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
